import React, { useEffect, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Translate from 'i18n-js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import styled, { keyframes } from 'styled-components';

import { IQrTypeEnum, ISurveyScanStageEnum, LanguageSelectionEnum } from 'api/HomeBase';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { ICommonCopies, ISelectLanguageAndDOBParams, ITrackDOBParams, ITrackDisclaimerCheckboxEngagementParams } from 'redux/slices/home/types';

import Text from 'components/Text';
import Button from 'components/Button';
import ToggleButton from 'components/ToggleButton';
import LanguageSelector from 'components/LanguageSelector';

import Utils from 'lib/Utils';

import agreementLOV from 'lib/LOVs/LanguageSelectionDOB';
import TimeTracker from 'components/TimeTracker';
import { ContainerStyles, ItemStyles } from './styles';

interface LanguageSelectionAndDOBProps {
    canSkipReward: boolean;
    selectingLanguageAndDOB: boolean;
    selectLanguageAndDOBError: string;
    commonCopies: ICommonCopies | undefined;
    langSelected: LanguageSelectionEnum | undefined;
    selectLanguageAndDOB: (params: ISelectLanguageAndDOBParams) => void;
    resetSelectLanguageAndDOB: () => void;
    trackDOB: (params: ITrackDOBParams) => void;
    getCommonCopies: (qrType: IQrTypeEnum, qrId: string) => void;
    getLanguagePack: (qrType: IQrTypeEnum, lang: LanguageSelectionEnum) => void;
    trackDisclaimerCheckboxEngagement: (params: ITrackDisclaimerCheckboxEngagementParams) => void;
    setLanguage: (lang: LanguageSelectionEnum) => void;
}

const LanguageSelectionAndDOB = (props: LanguageSelectionAndDOBProps): JSX.Element => {
    const {
        canSkipReward,
        selectingLanguageAndDOB,
        selectLanguageAndDOBError,
        commonCopies,
        selectLanguageAndDOB,
        resetSelectLanguageAndDOB,
        trackDOB,
        getCommonCopies,
        getLanguagePack,
        trackDisclaimerCheckboxEngagement,
        setLanguage,
        langSelected,
    } = props;

    const { qrId } = useParams();

    const [termsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState(false);
    const [privacyNoticeModalIsOpen, setPrivacyNoticeModalIsOpen] = useState(false);
    const [disclaimerModalIsOpen, setDisclaimerModalIsOpen] = useState(false);
    const [age, setAge] = useState<number>(0);

    const [disclaimerCheckbox, setDisclaimerCheckbox] = useState(false);
    const [industryDisclaimerCheckbox, setIndustryDisclaimerCheckbox] = useState(false);
    const [languageIsSelected] = useState(true);

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);

        const prevSelectedLanguage = Utils.LocalStorage.getItem('settingsUserLanguage') as string | undefined;
        const surveyLanguage = Utils.Helper.getBrowserLanguage(prevSelectedLanguage ?? navigator.language);

        setLanguage(surveyLanguage);

        if (!commonCopies && qrId) getCommonCopies(IQrTypeEnum.AnswerMultiple, qrId);

        return () => {
            if (selectLanguageAndDOBError) resetSelectLanguageAndDOB();
        };
    }, []);

    const handleDobPressed = (selectedDob: number) => {
        const yearOfBirth = dayjs().year() - selectedDob;

        trackDOB({ dob: `${yearOfBirth}-01-01` });
        setAge(selectedDob);
    };

    const setDisclaimerCheckboxEngagement = (tncAndPrivacyPolicyCheckbox: boolean, notFromTheseIndustriesCheckbox: boolean) => {
        setDisclaimerCheckbox(tncAndPrivacyPolicyCheckbox);
        setIndustryDisclaimerCheckbox(notFromTheseIndustriesCheckbox);
        const params = {
            tncAndPrivacyPolicyCheckbox,
            notFromTheseIndustriesCheckbox,
        };

        trackDisclaimerCheckboxEngagement(params);
    };

    const renderSubtitle = () => {
        let copies = '';
        if (commonCopies) {
            if (canSkipReward) {
                return Translate.t('languageSelectionAndDOBheaderContainerSubTitle2');
            }

            switch (langSelected) {
                case LanguageSelectionEnum.Malay:
                    copies = commonCopies.homeSubtitleMs ?? '';
                    break;
                case LanguageSelectionEnum.Chinese:
                    copies = commonCopies.homeSubtitleZh ?? '';
                    break;
                default:
                    copies = commonCopies.homeSubtitleEn ?? '';
                    break;
            }
        }

        return DOMPurify.sanitize(copies, { ALLOWED_TAGS: ['b', 'i', 'u'] });
    };

    const renderTermsAndConditionsBody = () => {
        if (commonCopies) {
            if (canSkipReward) {
                return (
                    <div>
                        <Text style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{Translate.t('skipRewardTermsAndConditionTitle')}</Text>
                        <ol>
                            <li>
                                {Translate.t('skipRewardTermsAndConditionContent1')}
                                <ol type='a'>
                                    <li>{Translate.t('skipRewardTermsAndConditionContent2')}</li>
                                    <li>{Translate.t('skipRewardTermsAndConditionContent3')}</li>
                                    <li>{Translate.t('skipRewardTermsAndConditionContent4')}</li>
                                </ol>
                            </li>
                            <li>
                                {Translate.t('skipRewardTermsAndConditionContent5')}
                            </li>
                        </ol>
                    </div>
                );
            }
            if (langSelected === LanguageSelectionEnum.Malay) return commonCopies.termsMs;
            if (langSelected === LanguageSelectionEnum.Chinese) return commonCopies.termsZh;
            return commonCopies.termsEn;
        } return '';
    };

    const renderPrivacyPolicyBody = () => {
        if (commonCopies) {
            if (canSkipReward) {
                return (
                    <div style={{ textAlign: 'justify' }}>
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle1')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent1')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle2')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent2')}</Text>
                        <br />
                        <ul>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent2subContent1')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent2subContent3')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent2subContent4')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent2subContent5')}</li>
                        </ul>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle3')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent3')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent4')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent17')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle4')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent5')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent6')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent7')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent8')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent9')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle5')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent10')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle6')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent11')}</Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent12')}</Text>
                        <br />
                        <ul>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent12SubContent1')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent12SubContent2')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent12SubContent3')}</li>
                            <li>{Translate.t('skipRewardPrivacyNoticeContent12SubContent4')}</li>
                        </ul>
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle7')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent14')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle8')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent15')}</Text>
                        <br />
                        <Text style={{ fontWeight: 'bold' }}>
                            {Translate.t('skipRewardPrivacyNoticeTitle9')}
                        </Text>
                        <Text>{Translate.t('skipRewardPrivacyNoticeContent16')}</Text>
                        <br />
                    </div>
                );
            }
            if (langSelected === LanguageSelectionEnum.Malay) return commonCopies.privacyPolicyMs;
            if (langSelected === LanguageSelectionEnum.Chinese) return commonCopies.privacyPolicyZh;
            return commonCopies.privacyPolicyEn;
        } return '';
    };

    const renderDisclaimerBody = () => {
        if (commonCopies) {
            if (canSkipReward) {
                return (
                    <div style={{ textAlign: 'justify' }}>
                        <Text>{Translate.t('skipRewardDisclaimerContent1')}</Text>
                        <br />
                        <Text>{Translate.t('skipRewardDisclaimerContent2')}</Text>
                        <br />
                        <Text>{Translate.t('skipRewardDisclaimerContent3')}</Text>
                    </div>
                );
            }
            if (langSelected === LanguageSelectionEnum.Malay) return commonCopies.disclaimerMs;
            if (langSelected === LanguageSelectionEnum.Chinese) return commonCopies.disclaimerZh;
            return commonCopies.disclaimerEn;
        } return '';
    };

    const renderTermsAndConditionsModal = () => {
        return (
            <Modal
                size='xl'
                centered
                isOpen={termsAndConditionsModalIsOpen}
                toggle={() => setTermsAndConditionsModalIsOpen(!termsAndConditionsModalIsOpen)}
            >
                <ModalHeader>
                    <div style={{
                        color: '#199BE1',
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }}
                    >
                        {Translate.t('languageSelectionAndDOBTermsAndConditionsModalHeader')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {renderTermsAndConditionsBody()}
                    </div>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => setTermsAndConditionsModalIsOpen(false)}
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                    >
                        {Translate.t('languageSelectionAndDOBTermsAndConditionsModalOkButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const renderPrivacyNoticeModal = () => {
        return (
            <Modal
                size='xl'
                centered
                isOpen={privacyNoticeModalIsOpen}
                toggle={() => setPrivacyNoticeModalIsOpen(!privacyNoticeModalIsOpen)}
            >
                <ModalHeader>
                    <div style={{
                        color: '#199BE1',
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }}
                    >
                        {Translate.t('languageSelectionAndDOBPrivacyNoticeModalHeader')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {renderPrivacyPolicyBody()}
                    </div>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => setPrivacyNoticeModalIsOpen(false)}
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                    >
                        {Translate.t('languageSelectionAndDOBPrivacyNoticeModalOkButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const renderDisclaimerModal = () => {
        return (
            <Modal
                size='xl'
                centered
                isOpen={disclaimerModalIsOpen}
                toggle={() => setDisclaimerModalIsOpen(!disclaimerModalIsOpen)}
            >
                <ModalHeader>
                    <div style={{
                        color: '#199BE1',
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }}
                    >
                        {Translate.t('languageSelectionAndDOBDisclaimerModalHeader')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {renderDisclaimerBody()}
                    </div>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => setDisclaimerModalIsOpen(false)}
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                    >
                        {Translate.t('languageSelectionAndDOBDisclaimerModalOkButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const agreementText = Translate.t('languageSelectionAndDOBAgreementText');
    const link = agreementText.split(/{(terms|disclaimer|privacyNotice)}/);
    const renderAgreementCheckboxText = link.map((part) => {
        if (part === agreementLOV.terms) {
            return <Button key={1} style={{ ...ItemStyles.DisclaimerButtons }} onClick={() => setTermsAndConditionsModalIsOpen(true)}>{Translate.t('languageSelectionAndDOBHeaderContainerTermsAndConditions')}</Button>;
        }
        if (part === agreementLOV.disclaimer) {
            return <Button key={2} style={{ ...ItemStyles.DisclaimerButtons }} onClick={() => setDisclaimerModalIsOpen(true)}>{Translate.t('languageSelectionAndDOBHeaderContainerDisclaimer')}</Button>;
        }
        if (part === agreementLOV.privacyNotice) {
            return <Button key={3} style={{ ...ItemStyles.DisclaimerButtons }} onClick={() => setPrivacyNoticeModalIsOpen(true)}>{Translate.t('languageSelectionAndDOBHeaderContainerPrivacyNotice')}</Button>;
        }
        return part;
    });

    const [startTime, setStartTime] = useState('');
    useEffect(() => {
        const time = dayjs();
        setStartTime(time.toISOString());
    }, []);

    return (
        <>
            <TimeTracker
                stage={ISurveyScanStageEnum.BondStWelcome}
            />
            <div style={{ ...ContainerStyles.mainContainer, height: 'auto', position: 'relative' }}>
                <div style={{ ...ContainerStyles.topContainer }}>
                    <WelcomeTitle isSelected={languageIsSelected}>
                        <Text style={{ color: 'white' }}>{commonCopies?.homeTitleEn}</Text>
                        <Text style={{ color: 'white' }}>{commonCopies?.homeTitleMs}</Text>
                        <Text style={{ color: 'white' }}>{commonCopies?.homeTitleZh}</Text>
                    </WelcomeTitle>
                    <LanguageSelector
                        selected={langSelected}
                        onSelect={setLanguage}
                    />
                    <WelcomeContent isSelected={languageIsSelected}>
                        <div style={{ marginTop: '60px' }} />
                        <div style={ContainerStyles.bondFlowDOBContainer}>
                            <div style={{ whiteSpace: 'pre-line', color: 'white' }} dangerouslySetInnerHTML={{ __html: renderSubtitle() }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ textTransform: 'uppercase' }} css={ItemStyles.pickYourBirthday}>
                                {Translate.t('languageSelectionAndDOBHeaderContainerAgeRange')}
                            </Text>
                            <ButtonGroup>
                                <ToggleButton isSelected={age === 18} onClick={() => handleDobPressed(18)}>&lt; 19</ToggleButton>
                                <ToggleButton isSelected={age === 19} onClick={() => handleDobPressed(19)}>19 - 39</ToggleButton>
                                <ToggleButton isSelected={age === 40} onClick={() => handleDobPressed(40)}>40 - 54</ToggleButton>
                                <ToggleButton isSelected={age === 56} onClick={() => handleDobPressed(56)}>55 &gt;</ToggleButton>
                            </ButtonGroup>

                        </div>
                    </WelcomeContent>
                </div>
                <WelcomeContent isSelected={languageIsSelected}>
                    <div style={ContainerStyles.bottomContainer}>
                        <div style={ContainerStyles.disclaimerContainer}>
                            <div style={{ display: 'flex' }}>

                                <input
                                    type='checkbox'
                                    name='disclaimerCheckbox'
                                    // value='checkbox_value'
                                    checked={disclaimerCheckbox}
                                    onChange={() => {
                                        setDisclaimerCheckboxEngagement(!disclaimerCheckbox, industryDisclaimerCheckbox);
                                    }}
                                    style={{ margin: '10px' }}
                                />
                                <div style={{ alignSelf: 'center' }}>
                                    {Translate.t('languageSelectionAndDOBHeaderContainerMessage1')}
                                </div>
                            </div>

                            <ol type='i'>
                                <li style={{ textIndent: '10px each-line' }}>
                                    {Translate.t('languageSelectionAndDOBHeaderContainerContent2')}
                                </li>
                                <li style={{ textIndent: '10px each-line' }}>
                                    {
                                        canSkipReward ? Translate.t('languageSelectionAndDOBHeaderContainerContent6')
                                            : Translate.t('languageSelectionAndDOBHeaderContainerContent5')
                                    }
                                </li>
                                <li style={{ textIndent: '10px each-line' }}>
                                    {renderAgreementCheckboxText}
                                </li>
                            </ol>

                            <div style={{ display: 'flex' }}>

                                <input
                                    type='checkbox'
                                    name='industryDisclaimerCheckbox'
                                    checked={industryDisclaimerCheckbox}
                                    onChange={() => {
                                        setDisclaimerCheckboxEngagement(disclaimerCheckbox, !industryDisclaimerCheckbox);
                                    }}
                                    style={{ margin: '10px' }}
                                />
                                <div style={{ alignSelf: 'center' }}>
                                    {Translate.t('languageSelectionAndDOBHeaderContainerMessage2')}
                                </div>
                            </div>

                            <ol type='i'>
                                <li style={{ textIndent: '10px each-line' }}>
                                    {Translate.t('languageSelectionAndDOBHeaderContainerContent7')}
                                </li>
                                <li style={{ textIndent: '10px each-line' }}>
                                    {Translate.t('languageSelectionAndDOBHeaderContainerContent8')}
                                </li>
                            </ol>
                        </div>
                        <Button
                            disabled={!langSelected || disclaimerCheckbox === false || industryDisclaimerCheckbox === false || selectingLanguageAndDOB}
                            onClick={() => {
                                if (langSelected && age) {
                                    const birthDate = dayjs().year(dayjs().year() - age).month(dayjs().month() - 1).date(1);
                                    selectLanguageAndDOB({ lang: langSelected, dob: birthDate.format('YYYY-MM-DD'), stage: ISurveyScanStageEnum.BondStWelcome, startTime });
                                    getLanguagePack(IQrTypeEnum.AnswerMultiple, langSelected);
                                }
                            }}
                            css={ItemStyles.submitButton}
                            style={{
                                opacity: langSelected && disclaimerCheckbox && industryDisclaimerCheckbox ? 1 : 0.5,
                                cursor: langSelected && disclaimerCheckbox && industryDisclaimerCheckbox ? 'pointer' : 'not-allowed',
                            }}
                        >
                            {selectingLanguageAndDOB ? <Spinner /> : Translate.t('languageSelectionAndDOBSubmitButton')}
                        </Button>

                    </div>
                </WelcomeContent>
                {renderTermsAndConditionsModal()}
                {renderPrivacyNoticeModal()}
                {renderDisclaimerModal()}
            </div>
        </>
    );
};

const entrance = keyframes`
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
        width: 200px;
        position: absolute;
        top:20%;
    }
    100%{
        letter-spacing: 0em;
        opacity: 1;
        width: 200px;
        position: absolute;
        top:20px;
    }
`;

const exit = keyframes`
    0%{
        letter-spacing: 0em;
        opacity: 1;
        width: 200px;
        position: absolute;
        top:20%;
    }
    100%{
        position: absolute;
        font-weight: normal;
        width: 100%;
        top: 20px;

    }
`;

const contentHidden = keyframes`
    0% {
        display: none;
        transform: translateY(1000px);
    }
    100%{
        display: none;
        transform: translateY(1000px);
    }
`;

const contentEntrance = keyframes`
    0%{
        display: none;
        transform: translateY(1000px);
    }
    50%{
        margin-top: 20px;
    }
    100%{
        margin-top: 20px;
        /* transform: translate(300px, 400px); */
    }
`;

const WelcomeTitle = styled.div<{isSelected: boolean}>`
    animation: ${props => (props.isSelected ? exit : entrance)} 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
`;

const WelcomeContent = styled.div<{isSelected: boolean}>`
    display: ${props => (props.isSelected ? 'flex' : 'none')};
    flex-direction: column;
    overflow-y: hidden;
    animation: ${props => (props.isSelected ? contentEntrance : contentHidden)} 1.5s both;
`;

const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 5px; 
    width: 50%;
`;

const mapStateToProps = (state: RootState) => ({
    trackingDOB: Selectors.homeGetTrackDOBAttempting(state),
    selectingLanguageAndDOB: Selectors.homeGetSelectLanguageAndDOBAttempting(state),
    selectLanguageAndDOBError: Selectors.homeGetSelectLanguageAndDOBError(state),
    commonCopies: Selectors.homeGetCommonCopies(state),
    canSkipReward: Selectors.homeGetScanInfoCanSkipReward(state),
    langSelected: Selectors.homeGetSelectedLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    selectLanguageAndDOB: (params: ISelectLanguageAndDOBParams) => dispatch(Actions.homeSelectLanguageAndDOBAttempt(params)),
    trackDOB: (params: ITrackDOBParams) => dispatch(Actions.homeTrackDOBAttempt(params)),
    resetSelectLanguageAndDOB: () => dispatch(Actions.homeResetSelectLanguageAndDOB()),
    getCommonCopies: (qrType: IQrTypeEnum, qrId: string) => dispatch(Actions.homeGetCommonCopiesAttempt({ qrType, qrId })),
    getLanguagePack: (qrType: IQrTypeEnum, lang: LanguageSelectionEnum) => dispatch(Actions.homeGetLanguagePackAttempt({ qrType, lang })),
    trackDisclaimerCheckboxEngagement: (params: ITrackDisclaimerCheckboxEngagementParams) => dispatch(Actions.homeTrackDisclaimerCheckboxEngagementAttempt(params)),
    setLanguage: (params: LanguageSelectionEnum) => dispatch(Actions.homeSetLanguage(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectionAndDOB);
