import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IOption, IDataToSubmit } from 'entities/surveys';
import Translate from 'translate/Translate';

import icons from 'assets/icons';

import Text from 'components/Text';

import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SelectOneStyles';
import { AnswerInput } from './ShortFormAnswer';

interface RadioButtonProps {
    selected: boolean;
}

const RadioButton = styled.button<RadioButtonProps>`
    height: 30px;
    width: 30px;

    border: none;

    background-color: transparent;

    display: flex;
    flex-basis: 50px;
    justify-content: center;
    align-items: center;

    color: ${props => (props.selected ? '#021778' : 'white')};

    #outer {
        position: absolute;
        color: white;
        width: 30px;
        height: 30px;

        border-radius: 50%;

        border: ${props => (props.selected ? '1px solid #021778' : '1px solid #A5AAB5')};
    }

    #circle {
        position: absolute;

        width: 20px;
        height: 20px;
    }
`;

interface SelectOneProps {
    surveyId: string;
    questionId: string;
    question: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    withShortFormAnswer?: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const SelectOne: FunctionComponent<SelectOneProps> = (props: SelectOneProps) => {
    const { surveyId, questionId, question, options, images, loading, withShortFormAnswer, changeQuestionStatus, submitAnswer } = props;

    const [selected, setSelected] = useState('');
    const [answered, setAnswered] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>();

    useEffect(() => {
        if (answered && answer && withShortFormAnswer) {
            changeQuestionStatus(questionId, true);
        } else {
            changeQuestionStatus(questionId, false);
        }
    }, [answered]);

    const handleAnswerInput = (answerData: string) => {
        if (answerData.trim().length === 0 || answerData === '') {
            setAnswered(false);
            setAnswer(undefined);
            return;
        }
        setAnswer(answerData);
        setAnswered(true);
    };

    const handleAnswerOnBlur = () => {
        const dataToSubmit = {
            surveyId,
            questionId,
            answer,
        };

        if (!answer || answer === undefined || answer === '') return;

        submitAnswer(dataToSubmit);
    };

    const shortAnswerClickHandler = (id: string, answerData?: string) => {
        setSelected(id);

        const dataToSubmit = {
            surveyId,
            questionId,
            answer: answerData || answer,
        };

        if (!answerData || answerData === undefined || answerData === '') {
            setAnswered(false);
            setAnswer(undefined);
            changeQuestionStatus(questionId, false);
        } else {
            setAnswer(answerData);
            setAnswered(true);
            submitAnswer(dataToSubmit);
            changeQuestionStatus(questionId, true);
        }
    };

    const radioClickHandler = (value: string) => {
        const chesterfield = [
            'Chesterfield Remix Mint – with FRESH ZONE that reduces smoke smell on your fingers when you rub it',
            'Chesterfield Remix Mint – dengan FRESH ZONE untuk mengurangkan bau asap pada jari anda apabila digosok.',
            'Chesterfield Remix Mint (薄荷醇) – 备有FRESH ZONE功能，只需稍微摩擦即可减少手指上的烟味'];

        const marlboroIce = [
            'Marlboro Ice Blast high cooling menthol with capsule and Smoke Smell Control features',
            'Marlboro Ice Blast – rasa dingin mentol yang kuat dengan kapsul dan ciri kawalan Smoke Smell Control',
            'Marlboro Ice Blast(冰凉版) – 高度清凉舒爽薄荷醇，备有胶囊和烟味控制功能',
        ];

        const chesterfieldRed = [
            'Chesterfield Red - with FRESH ZONE to reduce smoke smell on your fingers when you rub it',
            'Chesterfield Red - dengan FRESH ZONE untuk mengurangkan bau asap pada jari anda apabila digosok',
            'Chesterfield(红版) – 备有FRESH ZONE功能，只需稍微摩擦即可减少手指上的烟味',
        ];

        const marlboroRed = [
            'Marlboro Red',
            'Marlboro(红版)',
        ];

        setSelected(value);
        if (withShortFormAnswer) {
            if (chesterfield.some(sentence => sentence === value)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: chesterfield[0],
                };
                submitAnswer(dataToSubmit);
            } else if (marlboroIce.some(sentence => sentence === value)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: marlboroIce[0],
                };
                submitAnswer(dataToSubmit);
            } else if (chesterfieldRed.some(sentence => sentence === value)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: chesterfieldRed[0],
                };
                submitAnswer(dataToSubmit);
            } else if (marlboroRed.some(sentence => sentence === value)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: marlboroRed[0],
                };
                submitAnswer(dataToSubmit);
            } else {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: value,
                };
                submitAnswer(dataToSubmit);
            }
        } else {
            const dataToSubmit = {
                surveyId,
                questionId,
                selectedOptionId: value,
            };
            submitAnswer(dataToSubmit);
        }

        changeQuestionStatus(questionId, true);
        // api call here!
    };

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={images.length === 1}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                                style={{ pointerEvents: 'none' }}
                            />
                        );
                    })}
                </Grid>
            )}

            <div style={ContainerStyles.buttonsContainer}>
                {options.map((item, index) => {
                    const { value, id, image } = item;

                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(image && image.length > 0) && (
                                    image?.map(url => {
                                        return (
                                            <img
                                                src={url}
                                                alt='no img'
                                                style={{
                                                    height: '120px',
                                                    width: '120px',
                                                    marginRight: '10px',
                                                    marginTop: '25px',
                                                    marginLeft: '10px',
                                                    objectFit: 'contain',
                                                    marginBottom: '20px',
                                                    alignSelf: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        );
                                    })
                                )}
                            </div>
                            <div style={ContainerStyles.optionContainer}>
                                <RadioButton
                                    key={id}
                                    onClick={() => radioClickHandler(withShortFormAnswer ? value : id)}
                                    selected={selected === (withShortFormAnswer ? value : id)}
                                    disabled={selected === (withShortFormAnswer ? value : id)} // so that the api wont be called when they spam click
                                >
                                    <SVG src={icons.Groove} id='outer' />
                                    <SVG src={icons.Groove} id='circle' />
                                </RadioButton>
                                <Text css={ItemStyles.option}>{value}</Text>
                            </div>
                        </div>
                    );
                })}

                {withShortFormAnswer && (
                    <div style={ContainerStyles.optionContainer}>
                        <RadioButton
                            key={(options.length + 1)}
                            onClick={() => shortAnswerClickHandler((options.length + 1).toString(), answer)}
                            selected={selected === ((options.length + 1).toString() || answered || !!answer)}
                            disabled={selected === (options.length + 1).toString()} // so that the api wont be called when they spam click
                        >
                            <SVG src={icons.Groove} id='outer' />
                            <SVG src={icons.Groove} id='circle' />
                        </RadioButton>
                        <div style={ContainerStyles.buttonsContainer}>
                            <Text css={ItemStyles.option}>
                                {Translate.t('selectOneWithShortAnswerTitle')}
                            </Text>

                            <div>
                                <AnswerInput
                                    value={answer === undefined ? '' : answer}
                                    rows={2}
                                    onChange={(e) => handleAnswerInput(e.target.value)}
                                    onBlur={handleAnswerOnBlur}
                                    disabled={selected !== (options.length + 1).toString()}
                                />
                            </div>
                            {loading && (
                                <div style={ContainerStyles.loadingOverlay} />
                            )}
                        </div>
                    </div>
                )}

                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

SelectOne.defaultProps = {
    withShortFormAnswer: false,
};

export default connect(null, mapDispatchToProps)(SelectOne);
